export default {
    CONTRACT_ADDRESS:"0xb72c5DB7080b9976AB7db602fdF065AFE7114134",//staking contract address
    BUSD_ADDRESS:"0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56", // usdt token address
    DEFAULT_REFERRAL:"0xed7eB2501921e43c34031Df1F4e6732c77D89fc5", // default referrer
    DONATE_ADDRESS:"0xed7eB2501921e43c34031Df1F4e6732c77D89fc5",
    
    
    
    
    OVERFLOW_WITHDRAWTIME: 8 *24*60*60,
    ONE_DAY_WITHDRAWTIME: 24*60*60,

    BASE_PROFIT_PERCENT:126,
    CHAINID:56,
}